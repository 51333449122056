<template>
  <div class="document-revisions">

    <div v-for="(actionsForDay, key) in actions" :key="key">
      <div class="date-label d-flex justify-content-center align-items-center"
           :class="{'first-date-label': isFirstDate(key)}">
        <span>
          {{ key | revisionDay }}
        </span>
      </div>
      <div v-for="(action, index) in actionsForDay" :key="index">
        <div class="revision">
          <span> {{ key + ' ' + action.time | date_format("HH:mm")}} </span>
          <span> {{action.description}} </span>
        </div>
        <div class="points" v-if="index !== actionsForDay.length - 1"></div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "Revisions",
  computed: {
    ...mapGetters('document/revision', ['revisions']),
    ...mapGetters('actions', ['actions']),
  },
  methods: {
    ...mapActions('document/revision', ['fetchRevisions']),
    ...mapActions('actions', ['fetch']),
    show(document) {
        this.fetch({type: 'document', id: document.id})
        this.fetchRevisions(document.id)
    },
    isFirstDate(date) {
      return Object.keys(this.actions)[0] === date
    },
  },
  filters: {
    revisionDay: function (value) {

      if (value) {
        if (moment().format('yyyy-MM-DD') === value) {
          return 'Today';
        }

        const diff = moment.duration(moment().diff(moment(value, 'yyyy-MM-DD')));

        if (diff.asDays() < 2) {
          return 'Yesterday';
        }

        return moment(value, 'yyyy-MM-DD').format('D MMMM YYYY')
      }
    }
  }
}
</script>

<style scoped>

</style>