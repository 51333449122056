<template>
  <div class="d-flex">
    <div>
      <vue-ellipse-progress
          :progress="progress"
          :size="36"
          color="#61A321"
          fontSize="11px"
          fontColor="#212721"
          thicknes="30%"
      >
        <template v-slot:legend-value>
          <span slot="legend-value">%</span>
        </template>
      </vue-ellipse-progress>
    </div>
    <div class="flex-grow-1 pl-3">
      <div class="file-name">
        {{ fileName | truncate(40) }}
      </div>
      <div class="file-size" v-if="!hasError && !hasLocalError">
        {{ uploadedSize | bytes }} / {{ fileSize | bytes }}
      </div>
      <div class="file-error" v-if="hasError || hasLocalError">
        <span v-if="hasLocalError">
          {{ localError }}
        </span>
        <span v-else-if="hasError">
          {{ error }}
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="re-upload" v-on:click="reUploadHandler" v-if="hasError">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M10.2364 1.7625C9.14822 0.675 7.65478 0 5.99625 0C2.67917 0 0 2.685 0 6C0 9.315 2.67917 12 5.99625 12C8.7955 12 11.1295 10.0875 11.7974 7.5H10.2364C9.62101 9.2475 7.95497 10.5 5.99625 10.5C3.5122 10.5 1.49343 8.4825 1.49343 6C1.49343 3.5175 3.5122 1.5 5.99625 1.5C7.24203 1.5 8.35272 2.0175 9.16323 2.835L6.74672 5.25H12V0L10.2364 1.7625Z"
              fill="#FF3535"/>
        </svg>
      </div>
      <div class="cancel ml-4" v-on:click="cancelHandler">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.91615 5.00007L9.80995 1.10608C10.0633 0.852787 10.0633 0.443255 9.80995 0.189966C9.55667 -0.0633221 9.14714 -0.0633221 8.89386 0.189966L4.99994 4.08396L1.10614 0.189966C0.85274 -0.0633221 0.443335 -0.0633221 0.190051 0.189966C-0.0633505 0.443255 -0.0633505 0.852787 0.190051 1.10608L4.08385 5.00007L0.190051 8.89407C-0.0633505 9.14736 -0.0633505 9.55689 0.190051 9.81018C0.316278 9.93653 0.482247 10 0.648097 10C0.813947 10 0.979797 9.93653 1.10614 9.81018L4.99994 5.91618L8.89386 9.81018C9.0202 9.93653 9.18605 10 9.3519 10C9.51775 10 9.6836 9.93653 9.80995 9.81018C10.0633 9.55689 10.0633 9.14736 9.80995 8.89407L5.91615 5.00007Z"
                fill="#8D948D"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "UploadingFile",
  data() {
    return {
      availableTypes: [
        'image/png',
        'image/jpeg',
        'image/svg+xml',
        'application/pdf',
        'text/csv',
        'text/xml',
        'text/plain',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'message/rfc822',
        'application/vnd.apple.numbers',
        'application/zip',
        'application/vnd.ms-outlook'
      ],
      maxSize: 31457280,
      localError: null
    }
  },
  props: {
    file: {
      type: Object
    }
  },
  mounted() {
    this.uploadHandler();
  },
  computed: {
    ...mapGetters('document/uploading', {
      uploadingProgress: 'progress',
      uploadingErrors: 'errors',
      uploadingCancel: 'cancel',
      uploadingReplacedFiles: 'replacedFiles'
    }),
    ...mapGetters('auth/user/claim', ['selectedClaim']),
    ...mapGetters('auth/user', ['profile']),
    fileName: function () {
      return this.file.file.name
    },
    fileSize: function () {
      return this.file.file.size;
    },
    fileType: function () {
      return this.file.file.type;
    },
    progress: function () {
      const currentFile = this.file;
      const progress = this.uploadingProgress.find(function (file) {
        return file.id === currentFile.id;
      });

      if (_.isNull(progress) || _.isUndefined(progress)) {
        return 0;
      }

      return progress.value;
    },
    uploadedSize: function () {
      return this.fileSize * this.progress / 100;
    },
    existingId: function () {
      const currentFile = this.file;
      let replacedFile = this.uploadingReplacedFiles.find(function (file) {
        return file.uuid === currentFile.id;
      })
      if (_.isUndefined(replacedFile)) {
        return null;
      }
      return  replacedFile.existingId;
    },
    error: function () {
      const currentFile = this.file;
      const error = this.uploadingErrors.find(function (error) {
        return error.id === currentFile.id;
      });
      if (_.isUndefined(error)) {
        return
      }

      return error.value.message;
    },
    hasError: function () {
      const currentFile = this.file;
      const error = this.uploadingErrors.find(function (error) {
        return error.id === currentFile.id;
      });
      return !_.isEmpty(error)
    },
    hasLocalError: function () {
      return !_.isNull(this.localError);
    }
  },
  methods: {
    ...mapActions('document/uploading', ['upload', 'removeFile', 'clearError', 'updateProgress']),
    cancelHandler() {
      const currentFile = this.file;
      const cancel = this.uploadingCancel.find(function (item) {
        return item.id === currentFile.id
      })
      if (!_.isUndefined(cancel)) {
        cancel.value.cancel();
      }
      this.removeFile(currentFile.id)
    },
    reUploadHandler() {
      this.clearError(this.file.id);
      this.updateProgress({
        id: this.file.id,
        value: 0
      })
      this.uploadHandler();
    },
    uploadHandler() {
      if (!this.validate()) {
        return;
      }
      let formData = new FormData();
      formData.append('claim_id', this.selectedClaim.id);
      formData.append('fileId', this.file.id);
      formData.append('fileAction', this.file.action);
      formData.append('file', this.file.file)
      formData.append('profileId', this.profile.id)
      if (this.file.sensitive) {
        formData.append('sensitive', this.file.sensitive);
      }
      if (this.file.action === 'replace') {
        formData.append('document_id', this.existingId)
      }

      this.upload(formData);
    },
    validate() {
      this.localError = null;
      if (this.fileSize > this.maxSize) {
        this.localError = 'File exceed maximum file size';
        return false;
      }
      if (_.isNull(this.fileType) || _.isEmpty(this.fileType) || _.isUndefined(this.fileType)) {
        return true;
      }
      if (this.availableTypes.includes(this.fileType)) {
        return true
      } else {
        this.localError = 'File type is not supported';
        return false;
      }
    }
  },
  watch: {
    error: function () {
      this.updateProgress({
        id: this.file.id,
        value: 0
      })
    }
  }
}
</script>

<style scoped>

</style>