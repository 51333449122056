<template>
  <div>
    <div class="drag-and-drop-upload mt-4">
      <b-form-file
          :multiple="true"
          v-model="files"
          v-on:input="inputFilesHandler"
      >
        <template v-slot:drop-placeholder>
          <Placeholder></Placeholder>
        </template>
        <template v-slot:placeholder>
          <Placeholder></Placeholder>
        </template>
        <template v-slot:file-name>
          <Placeholder></Placeholder>
        </template>
      </b-form-file>
      <Window></Window>
    </div>
    <PreloadedFiles :files="preloadedFiles" @upload="uploadFiles"></PreloadedFiles>
  </div>


</template>

<script>
import Placeholder from "./Placeholder";
import Window from "../window/Window";
import {mapActions} from "vuex";
import PreloadedFiles from "./PreloadedFiles";

export default {
  name: "DragAndDropForm",
  components: {
    PreloadedFiles,
    Placeholder, Window
  },
  data() {
    return {
      files: [],
      preloadedFiles: []
    }
  },
  methods: {
    ...mapActions('document/uploading', ['addFile']),
    inputFilesHandler() {
      this.files.forEach((file) => {
        this.preloadedFiles.push({
          id: this.$uuid.v1(),
          action: 'upload',
          file: file,
          sensitive: 0
        })
      });
    },
    uploadFiles(files) {
      files.forEach((file) =>{
        this.addFile(file)
      });
      this.files = [];
      this.preloadedFiles = [];
    }
  },

}
</script>

<style scoped>

</style>