<template>
  <div>
    <span>Replace</span>
    <b-form-file
        ref="replaceFile"
        v-model="file"
        class="document-replace"
        v-on:input="inputFileHandler"
        plain
    ></b-form-file>
    <MsgBox ref="replaceDocumentMsgBox" message="Are you sure you want to replace this document" title="Confirm replace" ok-title="Yes, replace" />
  </div>
</template>

<script>

import {mapActions} from "vuex";
import MsgBox from "../../../../../components/MsgBox";

export default {
  name: "Replace",
  components: {MsgBox},
  data() {
    return {
      file: null
    }
  },
  props: {
    document: Object
  },
  methods: {
    ...mapActions('document/uploading', ['addFile', 'addReplacedFile']),
    inputFileHandler() {
      if (this.file === null) {
        return;
      }
      this.$refs.replaceDocumentMsgBox.show().then((resp) => {
        if (resp === true) {
          const uuid = this.$uuid.v1()
          this.addReplacedFile({
            existingId: this.document.id,
            uuid: uuid
          })
          this.addFile({
            id: uuid,
            action: 'replace',
            file: this.file
          })
        } else {
          this.file = null;
        }
      });
    },
    openBrowserWindow() {
      this.$refs.replaceFile.$el.click();
    }
  }
}
</script>

<style scoped>

</style>