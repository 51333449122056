<template>
  <div class="list-documents mt-3 d-flex flex-column">
    <div class="list-documents-header d-flex">
      <div class="flex-grow-1 label">Files list</div>
      <div class="search">
        <b-form-input
            v-model="search"
            placeholder="Search files"
            v-on:input="searchValueInputHandler"
        ></b-form-input>
      </div>
    </div>
    <div class="list-documents-table d-flex flex-column mt-3">
      <div class="mass-deletion">
        <div class="th-hover-layout" v-if="providerSelectedItems.length !== 0">
          <div class="d-flex h-100 align-items-center pl-3">
            <span>{{ providerSelectedItems.length }}</span>
            <span class="ml-1">file(s) selected</span>
            <span class="mx-2" v-if="canUploadDocuments">
              <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="2" cy="2" r="2" fill="#BAC0BA"/>
              </svg>
            </span>
            <span class="delete-selected-documents" v-if="canUploadDocuments" v-on:click="deleteSelectedDocumentsHandler">Delete</span>
          </div>
        </div>
      </div>

      <b-table
          :ref="refName"
          :items="items"
          :fields="fields"
          :busy.sync="loading"
          :sort-by.sync="sorting.sortKey"
          :sort-desc.sync="sorting.sortDesc"
          v-on:sort-changed="sortingChanged">

        <template #head(selected)>
          <b-form-checkbox
              v-model="selectAllState"
              v-on:change="selectAllHandler" />
        </template>

        <template #head(is_sensitive)="data">
          {{ data.label }}
          <span class="ml-2"
                v-b-tooltip.hover title="Files marked as sensitive cannot be viewed by users with restricted access rights. "><InfoIcon /></span>
        </template>

        <template #cell(selected)="data">
          <div class="form">
            <b-form-checkbox
                name="select_document"
                v-model="data.item.selected"
                v-on:change="changeSelectedHandler(data)"
                :disabled="(data.item.is_sensitive && !canUploadSensitiveDocuments) || data.item.source == 0 || isApproved(data.item)"
            />
          </div>
        </template>

        <template #cell(file_name)="data">
          <span class="name" @click="previewDocumentHandler(data)">
            {{ data.value }}
          </span>
        </template>

        <template #cell(created_at)="data">
          {{data.value | updatedDate}}
        </template>

        <template #cell(users_firstname)="data">
          {{data.value }} {{data.item.users_lastname}}
        </template>

        <template #cell(is_sensitive)="data">
          <div class="form">
            <b-form-checkbox
                v-model="data.item.is_sensitive"
                v-on:change="changeSensitiveHandler(data)"
                :disabled="!canUploadSensitiveDocuments || data.item.source == 0 || isApproved(data.item)"
            />
          </div>
        </template>

        <template #cell(actions)="data">
            <div class="d-flex">
                <div title="Add comment"
                     v-b-tooltip.hover
                     class="mx-2 btn-show-messages"
                     @click="showMessagesHandler(data)">
                    <DownloadIcon/>
                </div>
                <div v-on:click="downloadDocumentHandler(data)" v-b-tooltip.hover title="Download"
                     class="btn-download-document">
                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M10.261 7.40464C10.5519 7.14198 10.5814 6.6857 10.3268 6.38552C10.0722 6.08534 9.62999 6.05492 9.33905 6.31758L7.7 7.79728V0.722222C7.7 0.32335 7.3866 0 7 0C6.6134 0 6.3 0.32335 6.3 0.722222V7.79728L4.66095 6.31758C4.37001 6.05492 3.92777 6.08534 3.6732 6.38552C3.41862 6.6857 3.4481 7.14198 3.73905 7.40464L6.53905 9.93242C6.80296 10.1707 7.19704 10.1707 7.46095 9.93242L10.261 7.40464ZM1.4 9.38889C1.4 8.99002 1.0866 8.66667 0.7 8.66667C0.313401 8.66667 0 8.99002 0 9.38889V11.5556C0 12.3533 0.626801 13 1.4 13H12.6C13.3732 13 14 12.3533 14 11.5556V9.38889C14 8.99002 13.6866 8.66667 13.3 8.66667C12.9134 8.66667 12.6 8.99002 12.6 9.38889V11.5556H1.4V9.38889Z"
                              fill="#E83B2C"/>
                    </svg>
                </div>
                <div class="mx-2 btn-show-history-document" v-b-tooltip.hover title="Version history"
                     v-on:click="showRevisionDocumentHandler(data)">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M7 0C3.15 0 0 3.15 0 7C0 10.85 3.15 14 7 14C10.85 14 14 10.85 14 7C14 3.15 10.85 0 7 0ZM7 12.6C3.92 12.6 1.4 10.08 1.4 7C1.4 3.92 3.92 1.4 7 1.4C10.08 1.4 12.6 3.92 12.6 7C12.6 10.08 10.08 12.6 7 12.6ZM7.35 3.5H6.3V7.7L9.94 9.94L10.5 9.03L7.35 7.14V3.5Z"
                              fill="#E83B2C"/>
                    </svg>
                </div>
                <b-dropdown
                    dropleft
                    class="actions"
                    :ref="'dropdown-document-' + data.item.id ">
                    <template #button-content>
                        <svg width="2" height="12" viewBox="0 0 2 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M2 1C2 1.55228 1.55229 2 1 2C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0C1.55229 0 2 0.447715 2 1ZM2 6C2 6.55229 1.55229 7 1 7C0.447715 7 0 6.55229 0 6C0 5.44772 0.447715 5 1 5C1.55229 5 2 5.44772 2 6ZM1 12C1.55229 12 2 11.5523 2 11C2 10.4477 1.55229 10 1 10C0.447715 10 0 10.4477 0 11C0 11.5523 0.447715 12 1 12Z"
                                  fill="#E83B2C"/>
                        </svg>
                    </template>
                    <b-dropdown-text @click="() => openBrowserWindowHandler(data.item)" v-if="!isActionsDisabled(data.item)">
                        <ReplaceFile :ref="`replaceFile${data.item.id}`" :document="data.item"></ReplaceFile>
                    </b-dropdown-text>
                    <b-dropdown-item v-on:click="previewDocumentHandler(data)">
                        Preview
                    </b-dropdown-item>
                    <b-dropdown-item v-if="!isActionsDisabled(data.item)"
                                     v-on:click="deleteDocumentHandler(data)"
                    >Delete
                    </b-dropdown-item>
                </b-dropdown>
            </div>

        </template>
      </b-table>
      <b-pagination
          v-if="showPagination"
          v-model="pagination.currentPage"
          :total-rows="providerPagination.totalRows || pagination.totalRows"
          :per-page="providerPagination.perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          class="align-self-end"
          aria-controls="my-table"
      ></b-pagination>
    </div>
    <MsgBox ref="deleteDocumentMsgBox" message="Are you sure you want to delete this document?" ok-title="Yes, delete" title="Confirm delete" />
    <MsgBox ref="removeSensitiveDocumentMsgBox" message="Are you sure you want to remove the sensitive status of this document?" ok-title="Yes, remove" title="Remove sensitive status"/>
  </div>
</template>

<script>
import _ from "lodash";
import table from '../../../../mixins/table.mixin';
import {mapActions, mapGetters} from "vuex";
import Replace from "../uploading/dranganddrop/Replace";
import {DateTime} from 'luxon'
import MsgBox from "../../../../components/MsgBox";
import InfoIcon from "../../../../components/icons/InfoIcon";
import DownloadIcon from "../../../../components/icons/FeedbackIcon";

export default {
  name: "Documents",
  components: {
      DownloadIcon,
    MsgBox,
    'ReplaceFile': Replace,
    InfoIcon,
  },
  data() {
    return {
      items: [],
      selectAllState: false,
      search: null,
      defaultFields: [
        {
          key: 'selected',
          label: '',
        },
        {
          key: 'file_name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Date',
          sortable: true
        },
        {
          key: 'users_firstname',
          label: 'Uploaded by',
          sortable: true
        },
        {
          key: 'is_sensitive',
          label: 'Sensitive',
          sortable: false,
        },
        {
          key: 'actions',
          label: '',
        }
      ],
      selectedItems: [],
      pagination: {
        perPage: 10,
        currentPage: +this.$route.query.page || 1,
        totalRows: (+this.$route.params.page || +this.$route.query.page || 1) * 10,
      },
      sorting: {
        sortKey: this.$route.query.orderField || '',
        sortDesc: !this.$route.query.orderDirection || this.$route.query.orderDirection === 'desc',
      },

    }
  },
  mixins: [table],
  computed: {
    ...mapGetters('document/provider', {
      providerItems: 'items',
      providerSelectedItems: 'selectedItems',
      providerPagination: 'pagination',
      providerSorting: 'sorting',
      loading: 'loading',
    }),
    ...mapGetters('auth/user/claim', ['selectedClaim']),
    ...mapGetters('auth/user', [
        'canUploadDocuments',
        'canUploadAllDocuments',
        'canUploadSensitiveDocuments',
        'canAccessSensitiveDocuments'
    ]),
    ...mapGetters('document/uploading', ['successfullyUploadedFiles']),
    showPagination() {
      return this.providerPagination.totalRows > this.providerPagination.perPage;
    },
    fields: function () {
      if (this.canAccessSensitiveDocuments) {
        return this.defaultFields;
      }else {
        return this.defaultFields.filter(function (field) {
          return field.key !== 'is_sensitive';
        })
      }
    },

  },
  mounted() {

    this.fetchItems()

  },
  methods: {
    ...mapActions('document/provider', ['fetchProvider', 'selectAll', 'selectItem', ]),
    ...mapActions('document/deleting', ['deleteDocuments']),
    ...mapActions('document/sensitive', ['changeSensitivity']),
    ...mapActions('document/downloading', ['downloadDocument']),

    async fetchItems() {

      let queryParams = {
        claimId: this.selectedClaim.id,
        page: this.pagination.currentPage,
        itemPerPage: this.providerPagination.perPage,
        orderField: this.sorting.sortKey,
        orderDirection: this.sorting.sortDesc ? 'desc' : 'asc',
      };

      if (!_.isEmpty(this.search)) {
        if (_.isEmpty(queryParams.filter)) {
          queryParams.filter = {
            search: this.search
          };
        } else {
          queryParams.filter.search = this.search;
        }
      }

      queryParams.filter = JSON.stringify(queryParams.filter);

      await this.fetchProvider(queryParams);

      this.items = this.providerItems;
      this.selectAllState = false;

    },
    selectAllHandler() {
      this.selectAll(this.canUploadSensitiveDocuments)
    },
    sortingChanged(ctx) {
      this.sorting.sortKey = ctx.sortBy;
      this.sorting.sortDesc = ctx.sortDesc;

      this.fetchItems()
    },

    searchValueInputHandler() {
      this.fetchItems();
    },
    replaceDocumentHandler(data) {
      this.$refs.replaceFile.openBrowser(data.item)
    },
    previewDocumentHandler(data) {
      this.$router.push({ name: 'preview', params: { type: 'document', id: data.item.id } }).catch(() => {});
    },
    showMessagesHandler(data) {
        this.$router.push({ name: 'preview',
            params: { type: 'document', id: data.item.id },
            query: {action: 'messages', } })
    },
    deleteDocumentHandler(data) {
      this.$refs.deleteDocumentMsgBox.show()
          .then((value) => {
            if (value === true) {
              let ids = [];
              ids.push(data.item.id)
              this.deleteDocuments(ids).then(() => {
                this.fetchItems();
              })
            }
          })
          .catch(() => {})
    },
    deleteSelectedDocumentsHandler(){
      this.$refs.deleteDocumentMsgBox.show()
          .then((value) => {
            if (value === true) {
              let ids = []
              this.providerSelectedItems.forEach(function (item) {
                ids.push(item.id);
              });
              this.deleteDocuments(ids).then(() => {
                this.fetchItems();
              })
            }
          })
          .catch(() => {
          })
    },
    changeSelectedHandler(data) {
      this.selectItem(data.item)
    },
    changeSensitiveHandler(data) {
      if (data.value === false) {
        this.$refs.removeSensitiveDocumentMsgBox.show().then((resp) => {
          if (resp) {
            this.changeSensitivity({
              'document_id': data.item.id,
              'is_sensitive': data.value
            }).then(() => {
              this.fetchItems();
            })
          }
          else {
            this.fetchItems();
          }
        })
      } else {
        this.changeSensitivity({
          'document_id': data.item.id,
          'is_sensitive': data.value
        }).then(() => {
          this.fetchItems();
        })
      }
    },
    downloadDocumentHandler(data) {
      this.downloadDocument(data.item)
    },
    showRevisionDocumentHandler(data) {
      this.$emit('showDocumentRevisions', data)
    },
    openBrowserWindowHandler(data) {
      this.$refs['dropdown-document-' + data.id].hide()
      this.$refs[`replaceFile${data.id}`].openBrowserWindow();
    },
    isActionsDisabled(item) {
      if (item.final_sign_off_status === 'approval' || item.final_sign_off_status === 'conditional_approval') {
        return true;
      }
      if (item.source == 0) {
        return true
      }
      if (item.is_sensitive) {
        return !this.canUploadSensitiveDocuments;
      }else{
        return !this.canUploadDocuments;
      }
    },
    isApproved(item) {
      return item.final_sign_off_status === 'approval' || item.final_sign_off_status === 'conditional_approval';
    }
  },
  watch: {
    successfullyUploadedFiles: function (val) {
      if (!_.isEmpty(val)) {
        this.fetchItems();
      }
    },
    selectedClaim: function () {
      this.pagination = {...this.pagination, currentPage: 1};
      this.fetchItems();
    },
    providerItems: function (newVal){
      this.items = newVal;
    },
    'pagination.currentPage': function () {
      this.fetchItems()
    },
  },
  filters: {
    updatedDate: function (value) {
      if (value) {
        return DateTime.fromISO(value, { zone: process.env.VUE_APP_TIME_ZONE }).toFormat('dd.MM.yyyy HH:mm')
      }
    }
  }
}
</script>

<style scoped>

</style>