<template>
  <Workspace>
    <template v-slot:top-bar>
      <TopBar>
        <template v-slot:title>Documents</template>
      </TopBar>
    </template>
    <template v-slot:content>

      <div class="content flex-grow-1">
        <DragAndDropForm v-if="canUploadDocuments && isClaimSelected">
        </DragAndDropForm>

        <DocumentsList
            v-if="isClaimSelected"
            v-on:showDocumentRevisions="showDocumentRevisionsHandler"></DocumentsList>
      </div>

    </template>
    <template v-slot:panel>
      <RightSidePanel ref="rightSidePanel" :is-footer-shown="false">
        <template v-slot:title>
          Revisions
        </template>
        <template v-slot:content>
          <Revisions ref="revisions"></Revisions>
        </template>
        <template v-slot:action-title>

        </template>
      </RightSidePanel>
    </template>
  </Workspace>
</template>

<script>
import _ from "lodash";
import Workspace from "../layouts/Workspace";
import TopBar from "../layouts/workspace/TopBar";
import Form from "./parts/documents/uploading/dranganddrop/Form";
import Documents from "./parts/documents/list/Documents";
import {mapActions, mapGetters} from "vuex";
import RightSidePanel from "../layouts/workspace/RightSidePanel";
import Revisions from "./parts/documents/list/Revisions";
export default {
  name: "Documents",
  components:{
    Workspace,
    TopBar,
    'DragAndDropForm': Form,
    'DocumentsList': Documents,
    RightSidePanel,
    Revisions
  },
  destroyed() {
    this.clear();
  },
  computed: {
    ...mapGetters('auth/user', ['canUploadDocuments']),
    ...mapGetters('auth/user/claim', ['selectedClaim']),
    isClaimSelected: function () {
      return !_.isEmpty(this.selectedClaim)
    }
  },
  methods: {
    ...mapActions('document/uploading', ['clear']),
    showDocumentRevisionsHandler(event) {
      this.$refs.rightSidePanel.show();
      this.$refs.revisions.show(event.item);
    }
  }
}
</script>

<style scoped>

</style>