<template>
  <div class="upload-window" v-if="showUploadWindow">
    <div class="uploading-file" v-for="file in files" :key="file.id">
      <UploadingFile
          :file="file"
      ></UploadingFile>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import UploadingFile from "./UploadingFile";
import {mapGetters} from "vuex";

export default {
  name: "Window",
  components: {UploadingFile},
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters('document/uploading', ['files']),
    showUploadWindow: function () {
      return !_.isEmpty(this.files)
    }
  }
}
</script>

<style scoped>

</style>