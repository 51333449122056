<template>
  <div class="pleaded-files" v-if="files.length > 0">
    <hr>
    <div class="d-flex flex-column">
      <div>
        <b-form-group
            label="If your file contains sensitive information tick this box before uploading:"
        >
          <b-form-checkbox-group

              v-model="sensitive"
              :options="fileOptions"
              class="mb-3"
              value-field="item"
              text-field="name"
              stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
      <div class="d-flex justify-content-end">
        <div @click="uploadFiles" class="upload-btn d-flex justify-content-center align-items-center">
          <span>Upload files</span>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>

export default {
  name: "PreloadedFiles",
  data() {
    return {
      sensitive: []
    }
  },
  props: {
    files: {
      type: Array
    }
  },
  computed: {
    fileOptions: function () {
      return this.files.map((file) => {
          return {
            'item': file,
            'name': file.file.name
          }
      })
    }
  },
  methods: {
    uploadFiles() {
      let sensitive = this.sensitive.map((file) => {
        return file.id
      })

      let files = this.files.map((file) => {

        if (sensitive.includes(file.id)) {
          file.sensitive = 1;
        } else {
          file.sensitive = 0;
        }
        return file;
      })

      this.$emit('upload', files)
    }
  }
}
</script>

<style scoped>
  .upload-btn {
    font-family: Rubrik, sans-serif;
    width: 8rem;
    height: 2rem;
    border-radius: 1rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    cursor: pointer;
    margin-right: 2.5rem;
    background-color: #E83B2C;
    border-width: 0;
    transition: 0.3s
  }

</style>