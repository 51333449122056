<template>

  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C15.9952 12.416 12.416 15.9952 8 16ZM1.6 8.1376C1.61818 9.82893 2.30516 11.4443 3.51078 12.6306C4.7164 13.817 6.34263 14.4778 8.03403 14.4687C9.72543 14.4596 11.3445 13.7813 12.5373 12.5821C13.7301 11.3829 14.3996 9.76023 14.3996 8.0688C14.3996 6.37737 13.7301 4.75472 12.5373 3.5555C11.3445 2.35627 9.72543 1.67798 8.03403 1.66889C6.34263 1.6598 4.7164 2.32064 3.51078 3.50698C2.30516 4.69331 1.61818 6.30867 1.6 8V8.1376ZM9.6 12H7.2V8.8H6.4V7.2H8.8V10.4H9.6V12ZM8.8 5.6H7.2V4H8.8V5.6Z" fill="#316579"/>
  </svg>


</template>

<script>
  export default {
    name: 'InfoIcon',
  }

</script>